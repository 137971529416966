import React, { useContext, useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import { TimePeriodContext } from '../../../context/TimePeriodContext';
import Loader from '../../../components/loader';
import ErrorIndicator from '../../../components/error-indicator';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import SyncChart from './sre-chart-sync';
import useParamsQuery from '../../../hooks/useParamsQuery';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import moment from 'moment';
import _ from 'lodash';

const ErrorBudgetsChart = ({
    selectedService,
    serviceState,
    step,
    chartState,
    isInTeam,
    cardTimeperiod,
}) => {
    const { t } = useTranslation();
    const [timePeriodState] = useContext(TimePeriodContext);
    const [zoomTimeperiod, setZoomTimeperiod] = useState(null);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;
    const errorBudgetChartOptionsBase = {
        colors: [
            '#7902D7',
            '#F8C238',
            '#15A2BB',
            '#81BC42',
            '#D6DA33',
            '#484A53',
        ],
        chart: {
            type: 'area',
            height: 460,
            zooming: {
                type: 'x',
            },
            events: {
                selection: function (event) {
                    if (event.xAxis) {
                        const selectedMin = event.xAxis[0]?.min;
                        const selectedMax = event.xAxis[0]?.max;

                        setZoomTimeperiod([selectedMin, selectedMax]);
                    } else {
                        setZoomTimeperiod(null);
                    }
                },
                render: function () {
                    this?.resetZoomButton?.hide();
                },
            },
        },

        title: {
            text: '',
        },
        yAxis: {
            //primary Y axis
            max: 100,

            ceiling: 100,
            alignTicks: false,
            endOnTick: false,
            labels: {
                format: '{value}%',
                // formatter() {
                //     if (this.value < 0) {
                //         return `<span style="color: #FF6384">${this.value}%</span>`;
                //     } else {
                //         return `<span style="color: #8EC358">${this.value}%</span>`;
                //     }
                // },
            },
            title: {
                enabled: false,
            },

            plotBands: [
                {
                    color: '#f4f9ee',
                    from: 0,
                    to: 10000000000,
                },
                {
                    // mark the weekend
                    color: '#f9ebeb',
                    from: -10000000000,
                    to: 0,
                },
            ],
            // always show max tick
            tickPositioner: function () {
                var tickPositions = this.tickPositions,
                    lastTick = tickPositions[tickPositions.length - 1],
                    max = this.options.max;

                if (lastTick > max) {
                    tickPositions.pop(); // remove last tick
                    tickPositions.push(max);
                }
            },
        },
        xAxis: {
            crosshair: true,
            type: 'datetime',
            labels: {
                format: '{value:%d %b %H:%M}',
                rotation: -50,
                align: 'right',
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                // negativeColor: '#FF6384',
                opacity: 0.6,
                marker: {
                    enabled: false,
                },
            },
        },
        tooltip: {
            shared: true,
            formatter: function () {
                return this.points.reduce(function (s, point) {
                    return (
                        s +
                        '<br/>' +
                        `<span style="color:${point.color}">${point.series.name}</span>` +
                        ' ' +
                        t('sre.last') +
                        ' ' +
                        step +
                        ': ' +
                        (point.y.toFixed(3) == 100 ? 100 : point.y.toFixed(3)) +
                        '%'
                    );
                }, moment(this.x).format('dddd, MMM D, HH:mm:ss'));
            },
        },

        legend: {
            labelFormatter: function () {
                return this.userOptions.slo_name
                    ? `<b>SLO: </b><span>${
                          this.userOptions.slo_name ?? ''
                      }</span><br>
                <b>${capitalizeFirstLetter(
                    isInTeam
                        ? 'service'
                        : t('hours_page.hours_report.description')
                )}: </b><span>${
                          isInTeam
                              ? this.userOptions.service
                              : this.userOptions.slo_description ?? ''
                      }</span><br> 

                <b>${t('sre.slo_target')}: </b><span>${
                          this.userOptions.slo_target ?? ''
                      }</span><br>`
                    : `<b>${this.userOptions.title ?? ''}</b>`;
            },
        },
    };
    const eventsChartOptionsBase = {
        colors: ['#FF6384', '#8ec358'],
        chart: {
            type: 'column',
            height: 220,
            zooming: {
                type: 'x',
                resetButton: {
                    position: { x: 0, y: -50 },
                    theme: {
                        display: 'none',
                        // fill: 'white',
                        // stroke: '#1fb8d3',
                        // style: {
                        //     color: '#1fb8d3',
                        //     fontSize: 14,
                        // },
                        // r: 4,
                        // states: {
                        //     hover: {
                        //         fill: '#1fb8d3',
                        //         style: {
                        //             color: 'white',
                        //         },
                        //     },
                        // },
                    },
                },
            },
            events: {
                selection: function (event) {
                    if (event.xAxis) {
                        const selectedMin = event.xAxis[0]?.min;
                        const selectedMax = event.xAxis[0]?.max;

                        setZoomTimeperiod([selectedMin, selectedMax]);
                    } else {
                        setZoomTimeperiod(null);
                    }
                },
                render: function () {
                    this?.resetZoomButton?.hide();
                },
            },
        },
        title: {
            text: '',
        },
        yAxis: {
            //secondary Y axis
            labels: {
                format: '{value}',
            },
            title: {
                enabled: false,
            },
            min: 0,
        },

        xAxis: {
            crosshair: true,
            type: 'datetime',
            labels: {
                format: '{value:%d %b %H:%M}',
                rotation: -50,
                align: 'right',
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                stacking: 'normal',
            },
            column: {
                pointWidth: 10,
            },
        },

        tooltip: {
            formatter: function () {
                return this.points.reduce(
                    function (s, point) {
                        return (
                            point.series.name +
                            ' ' +
                            t('sre.in_the_last_time_step') +
                            step +
                            ': ' +
                            '<b>' +
                            point.y +
                            '</b><br/>' +
                            s
                        );
                    },
                    this.points.length > 1
                        ? 'Total: ' + '<b>' + this.points[0].total + '</b>'
                        : ''
                );
            },
            shared: true,
        },

        legend: {
            labelFormatter: function () {
                return this.userOptions.title
                    ? `<span><b>${this.userOptions.title + ':' ?? ''} ${
                          this.userOptions.name ?? ''
                      }</b></span><br>`
                    : `<b>${this.userOptions.name ?? ''}</b>`;
            },
        },
    };

    const { setIsOpen, currentStep, isOpen } = useTour();
    const query = useParamsQuery();

    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const featuresList = serviceState?.data?.features?.map(
        (feature) => feature.slo_name
    );

    const [data, setData] = useState([{ data: [null, null] }]);
    const [dataFeaturesVisibility, setDataFeaturesVisibility] = useState(null);
    const [dataEvents, setDataEvents] = useState([{ data: [null, null] }]);
    const [wasChartDataFormatted, setWasChartDataFormatted] = useState(false);
    const [wasStepOneDataFormatted, setWasStepOneDataFormatted] =
        useState(false);
    const [wasStepTwoDataFormatted, setWasStepTwoDataFormatted] =
        useState(false);
    const [loadingItemIndex, setLoadingItemIndex] = useState(0);

    const [zoomedDataEvents, setZoomedDataEvents] = useState(null);
    const [zoomedData, setZoomedData] = useState(null);
    const [eventsChartSeries, setEventsChartSeries] = useState(null);
    const [errorBudgetChartSeries, setErrorBudgetChartSeries] = useState(null);

    useEffect(() => {
        query.get('tour') === 'true' &&
            setTimeout(() => {
                setIsOpen(true);
            }, 3000);
        return () => setIsOpen(false);
    }, []);

    useEffect(() => {
        setWasChartDataFormatted(false);
    }, [
        dateTimeStart,
        dateTimeEnd,
        selectedService,
        selectedOrgHash,
        step,
        cardTimeperiod,
    ]);

    useEffect(() => {
        if (chartState?.data?.length) {
            let formattedData = data;
            if (demoOrgHash === selectedOrgHash && isOpen) {
                if (currentStep === 0) {
                    formattedData = [chartState.data[0]].map((feature) => {
                        feature.data &&
                            feature.data.map((item) => {
                                if (
                                    !wasChartDataFormatted ||
                                    !wasStepOneDataFormatted
                                ) {
                                    item[0] = item[0] * 1000;
                                }
                                return item;
                            });
                        return feature;
                    });
                    setWasStepOneDataFormatted(true);
                } else if (currentStep === 1) {
                    formattedData = [chartState.data[1]].map((feature) => {
                        feature.data &&
                            feature.data.map((item) => {
                                if (
                                    !wasChartDataFormatted ||
                                    !wasStepTwoDataFormatted
                                ) {
                                    item[0] = item[0] * 1000;
                                }
                                return item;
                            });
                        return feature;
                    });
                    setWasStepTwoDataFormatted(true);
                    wasStepOneDataFormatted && setWasChartDataFormatted(true);
                }
            } else {
                if (!wasChartDataFormatted) {
                    if (wasStepOneDataFormatted && !wasStepTwoDataFormatted) {
                        formattedData = chartState.data.map(
                            (feature, index) => {
                                if (index !== 0) {
                                    feature.data &&
                                        feature.data.map((item) => {
                                            item[0] = item[0] * 1000;
                                            return item;
                                        });
                                }

                                return feature;
                            }
                        );
                        setWasStepTwoDataFormatted(true);
                        setWasChartDataFormatted(true);
                    } else if (
                        wasStepTwoDataFormatted &&
                        !wasStepOneDataFormatted
                    ) {
                        formattedData = chartState.data.map(
                            (feature, index) => {
                                if (index === 0) {
                                    feature.data &&
                                        feature.data.map((item) => {
                                            item[0] = item[0] * 1000;
                                            return item;
                                        });
                                }
                                return feature;
                            }
                        );
                        setWasStepOneDataFormatted(true);
                        setWasChartDataFormatted(true);
                    } else {
                        formattedData = chartState.data.map((feature) => {
                            feature.data &&
                                feature.data.map((item) => {
                                    item[0] = item[0] * 1000;
                                    return item;
                                });
                            return feature;
                        });
                    }
                    !isInTeam && setWasChartDataFormatted(true);
                } else {
                    formattedData = chartState.data.map((feature) => {
                        feature.data &&
                            feature.data.map((item) => {
                                return item;
                            });
                        return feature;
                    });
                    setWasChartDataFormatted(true);
                }
            }

            const formattedDataWithOptions = formattedData.map(
                (dataSeries, i) => {
                    let formattedSeries = {
                        ...dataSeries,
                        name: dataSeries.feature,
                        tooltip: {
                            valueSuffix: ' %',
                        },
                        visible: isInTeam && i !== 0 ? false : true,
                    };
                    return formattedSeries;
                }
            );

            setData(formattedDataWithOptions);

            if (!isInTeam) {
                let eventsData = formattedData.reduce(
                    (acc, seriesData, index) => {
                        let columnsBadSeries = {
                            title: seriesData.slo_name,
                            type: 'column',
                            data: [...seriesData.data].map((dataItem) => {
                                return [dataItem[0], dataItem[2].bad_events];
                            }),
                            name:
                                capitalizeFirstLetter(t('sre.bad')) +
                                ' ' +
                                capitalizeFirstLetter(t('sre.events')),
                            color: '#FF6384',
                            id: index.toString(),
                        };

                        let columnsGoodSeries = {
                            title: seriesData.slo_name,
                            type: 'column',
                            data: [...seriesData.data].map((dataItem) => {
                                return [dataItem[0], dataItem[2].good_events];
                            }),
                            name:
                                capitalizeFirstLetter(t('sre.good')) +
                                ' ' +
                                capitalizeFirstLetter(t('sre.events')),
                            color: '#8ec358',
                            visible: false,
                        };

                        acc = [...acc, columnsBadSeries, columnsGoodSeries];

                        return acc;
                    },
                    []
                );
                setDataEvents([...eventsData]);
            }
        }
    }, [chartState.data, currentStep, isOpen, serviceState]);

    useEffect(() => {
        const visibility = data.reduce((acc, dataSeries, i) => {
            return {
                ...acc,
                [dataSeries.slo_name]: true,
            };
        }, {});

        setDataFeaturesVisibility(visibility);
        setErrorBudgetChartSeries((prevState) => {
            return { ...prevState, series: data };
        });
    }, [data]);

    useEffect(() => {
        if (chartState?.loading) {
            if (
                t('common.loading_texts', { returnObjects: true })?.length - 2 >
                loadingItemIndex
            ) {
                setTimeout(() => {
                    setLoadingItemIndex((prev) => prev + 1);
                }, 3000);
            } else {
                setTimeout(() => {
                    setLoadingItemIndex(0);
                }, 3000);
            }
        }
    }, [loadingItemIndex, chartState.loading]);

    useEffect(() => {
        if (
            dataFeaturesVisibility &&
            Object.keys(dataFeaturesVisibility)?.length
        ) {
            // update events data
            const copiedDataEvents = _.cloneDeep(dataEvents);
            const updatedEventsOptions = Object.keys(
                dataFeaturesVisibility
            ).reduce((acc, featureName) => {
                const updatedAcc = {
                    ...acc,
                    [featureName]: {
                        ...eventsChartOptionsBase,
                        series: zoomTimeperiod
                            ? [...zoomedDataEvents]?.filter(
                                  (dataSeries) =>
                                      dataSeries.title === featureName
                              )
                            : [...copiedDataEvents].filter(
                                  (dataSeries) =>
                                      dataSeries.title === featureName
                              ),
                    },
                };
                return updatedAcc;
            }, {});
            setEventsChartSeries(updatedEventsOptions);

            // update error budgets chart data
            const copiedData = _.cloneDeep(data);
            const updatedErrorBudgetOptions = {
                ...errorBudgetChartOptionsBase,
                series: zoomTimeperiod ? [...zoomedData] : [...copiedData],
            };
            setErrorBudgetChartSeries(updatedErrorBudgetOptions);
        }
    }, [
        dataFeaturesVisibility,
        zoomedDataEvents,
        dataEvents,
        zoomedData,
        data,
    ]);

    useEffect(() => {
        const copiedDataEvents = _.cloneDeep(dataEvents);
        const copiedData = _.cloneDeep(data);
        if (!zoomTimeperiod) {
            setZoomedDataEvents([...copiedDataEvents]);
            setZoomedData([...copiedData]);
        } else {
            if (copiedDataEvents) {
                const filteredEvents = filterDataEvents(
                    [...copiedDataEvents],
                    zoomTimeperiod[0],
                    zoomTimeperiod[1]
                );

                setZoomedDataEvents(filteredEvents);
            }
            if (copiedData) {
                const filteredData = filterDataEvents(
                    [...copiedData],
                    zoomTimeperiod[0],
                    zoomTimeperiod[1]
                );
                setZoomedData([...filteredData]);
            }
        }
    }, [zoomTimeperiod]);

    if (!featuresList?.length && !isInTeam) {
        return (
            <div className="mr-5" style={{ width: '100%', height: '190px' }}>
                <p className="text-center text-gray-500 text-xl p-4">
                    No SLOs. Add a SLO to start gathering data
                </p>
            </div>
        );
    }

    if (chartState.loading) {
        return (
            <div
                className="mr-5 flex justify-center items-center"
                style={{ width: '100%', height: '394px' }}
            >
                <div className="flex flex-col items-center justify-center gap-4">
                    <Loader
                        color={'#C2C7D7'}
                        size={35}
                        speedMultiplier={0.8}
                        css={{ margin: '30px auto', display: 'block' }}
                    />
                    <p className="text-gray-400 text-sm">
                        {t(`common.loading_texts.${loadingItemIndex}`)}...
                    </p>
                </div>
            </div>
        );
    }

    if (chartState.error) {
        return (
            <div className="mr-5" style={{ width: '100%', height: '190px' }}>
                <ErrorIndicator error={chartState.error} />
            </div>
        );
    }

    if (chartState.data && chartState.data.length === 0) {
        return (
            <div className="mr-5" style={{ width: '100%', height: '190px' }}>
                <p className="text-center text-gray-500 text-xl p-4">
                    {capitalizeFirstLetter(t('sre.no_data'))}
                </p>
            </div>
        );
    }

    function filterDataEvents(fullData, min, max) {
        const filtered = fullData?.map((dataset) => {
            const updatedData = dataset?.data?.filter((item) => {
                if (item?.length && item[0] >= min && item[0] <= max) {
                    return item;
                }
            });
            return { ...dataset, data: [...updatedData] };
        });
        return filtered;
    }

    return (
        <div
            data-tour="r-step-page-4"
            data-tour-2="r-step-page-5"
            className="relative"
        >
            {zoomTimeperiod ? (
                <button
                    type="button"
                    className="absolute right-2 -top-8 btn-white-hover-blue"
                    onClick={() => {
                        setZoomTimeperiod(null);
                    }}
                >
                    Reset zoom
                </button>
            ) : null}
            <SyncChart options={errorBudgetChartSeries} />
            {isInTeam ? null : (
                <div className="mt-4">
                    {dataFeaturesVisibility &&
                        Object.keys(dataFeaturesVisibility).map(
                            (featureName) => {
                                if (dataFeaturesVisibility[featureName]) {
                                    return (
                                        <div key={featureName}>
                                            <div className="flex justify-between items-center">
                                                <p className="mb-4 font-display text-xl font-light ">
                                                    {featureName} good/bad
                                                    events
                                                </p>
                                                {zoomTimeperiod ? (
                                                    <button
                                                        type="button"
                                                        className="btn-white-hover-blue"
                                                        onClick={() => {
                                                            setZoomTimeperiod(
                                                                null
                                                            );
                                                        }}
                                                    >
                                                        Reset zoom
                                                    </button>
                                                ) : null}
                                            </div>
                                            <SyncChart
                                                options={
                                                    eventsChartSeries
                                                        ? eventsChartSeries[
                                                              featureName
                                                          ]
                                                        : null
                                                }
                                            />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )}
                </div>
            )}
        </div>
    );
};

export default ErrorBudgetsChart;
